 @import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

:root {
    font-size: 60%;
    /*--color-background: #EFEFEF;
    --color-background-dark: #51517B;
    --color-text-white: #FFFFFF; 
    --color-text-blue: #51517B;
    --color-text-brown: #AC6633;
    --color-text-black: #000000;
    --color-text-gray: #E0E0E0;
    --color-text-dark-gray: #bebebe;
    --color-button: #51517B;
    --color-button-light: #EFEFEF;
    --color-button-white: #FFFFFF;  
    --color-button-text: #51517B;
    --color-button-text-hover: #616a99;
    --color-button-text-light: #FFFFFF;
    --color-button-text-light-hover: #EFEFEF;*/
    --color-background: #EFEFEF;
    --color-background-white: #FFFFFF;
    --color-background-light: #51517B;
    --color-background-dark: #000000;
    --color-background-login-dark: #868686;
    --color-background-login-light: #FFFFFF;
    --color-background-icon: #dadada;
    --color-background-bundle: #004AAD;
    --color-background-bundle-value: #17498b;
    --color-background-bundle-dark: #000000;
    --color-user-border: #868686;
    --color-text-white: #FFFFFF; 
    --color-text-blue: #51517B;
    --color-text-blue-signup: #050570;
    --color-text-value: #2424ce;
    --color-text-brown: #AC6633;
    --color-text-brown-light: #f19f64;
    --color-text-black: #000000;
    --color-text-gray: #E0E0E0;
    --color-text-red: #f41717;
    --color-text-dark-gray: #868686;
    --color-text-yellow: #DBBB6B;
    --color-button: #51517B;
    --color-button-dark: #000000;
    --color-button-light: #51517B;
    --color-button-white: #FFFFFF;
    --color-button-bundle: #827040;
    --color-button-bundle-light: #DBBB6B;
    --color-button-text: #51517B;
    --color-button-text-black: #000000;
    --color-button-text-green: #046B38;
    --color-button-text-green-hover: #08a959;
    --color-button-text-black-hover: #3a3a3a;
    --color-button-text-hover: #616a99;
    --color-button-text-light: #FFFFFF;
    --color-button-text-light-hover: #EFEFEF;
    --color-bundle-value-dark: #a38d50;
    --color-bundle-value: #DBBB6B;
    --color-bundle-value-light: #ffd876;
}

* {
    margin: 0;
    padding: 0;    
    box-sizing: border-box;
}

html, body, #root {
    height: 100vh;
}

body {
    background: var(--color-background);   
}

#root {
    display: flex;
    align-items: center;
    justify-content: center;
}

body,
input,
button,
textarea {
    font: 500 1.6rem barlow;
    color: var(--color-text-base);
}

.container {
    width: 90vw;    
}

@media (min-width: 700px) {
    :root {
        font-size: 62.5%;
    }
}