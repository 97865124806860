.ebook {
  width: 100%;
  height: 90rem;  
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background-light));
}

.ebook .ebook-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;  
}

.ebook .ebook-content strong {
  font: 600 1.8rem barlow;
  color: var(--color-text-white);  
}

.ebook .ebook-content .ebook-main {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;  
}

.ebook .ebook-content .ebook-main .ebook-text{
  width: 90%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

.ebook .ebook-content .ebook-main .ebook-text p{
  font: 300 1.8rem barlow;
  color: var(--color-text-white);
  text-align: center;
  margin-bottom: 1rem; 
}

.ebook .ebook-content .ebook-main .ebook-text strong{
  font: 600 2.4rem barlow;
  margin-bottom: 1rem;
}

.ebook .ebook-content .ebook-main .ebook-text button {
  width: 18rem;
  height: 4rem;
  background-image: linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light));
  color: var(--color-button-text);
  border: 0;
  border-radius: 0.3rem;
  cursor: pointer;
  font: 600 2rem barlow;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.2s;
  margin-top: 2rem;
}

.ebook .ebook-content .ebook-main .ebook-text a {
  text-decoration-color: var(--color-button-text);
}

.ebook .ebook-content .ebook-main .ebook-text button:hover {
  color: var(--color-button-text-hover);
}

.ebook .ebook-content .ebook-main .ebook-image{
  width: 30rem;
  height: 43.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center; 
}

.ebook .ebook-content .ebook-main .ebook-image img {
  width: 100%;
  height: 100%;
} 

@media (min-width: 600px) {
  .ebook .ebook-content strong {
    font: 600 2.4rem barlow;
  }

  .ebook .ebook-content .ebook-main .ebook-text{
    width: 80%;
    height: 40%;    
  }
  
  .ebook .ebook-content .ebook-main .ebook-text p{
    font: 300 2.1rem barlow;    
  }
  
  .ebook .ebook-content .ebook-main .ebook-text strong{
    font: 600 2.4rem barlow;   
  }
}

@media (min-width: 1200px) {
  .ebook .ebook-content {
    justify-content: space-evenly;
  }

  .ebook .ebook-content strong {
    font: 600 4.2rem barlow;
  }

  .ebook .ebook-content .ebook-main {
    height: 70%;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
  }

  .ebook .ebook-content .ebook-main .ebook-text{
    width: 45rem;
    height: 60rem;
    margin-right: 5rem;  
  }

  .ebook .ebook-content .ebook-main .ebook-text p{
    font: 300 2.5rem barlow;
    line-height: 3.8rem;
    margin-bottom: 5rem;  
  }
  
  .ebook .ebook-content .ebook-main .ebook-text strong{
    font: 600 3.7rem barlow;
    margin-top: 1rem;
    margin-bottom: 1rem;  
  }

  .ebook .ebook-content .ebook-main .ebook-text button {
    width: 22rem;
    height: 5rem;    
    font: 600 2.5rem barlow;    
    margin-top: 3rem;
  }

  .ebook .ebook-content .ebook-main .ebook-image{
    width: 40rem;
    height: 57.9rem;
    margin-left: 5rem;   
  }  
}