#error-page {
	width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background-light));
}

.error-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background-light));
}

.error-container h2 {
  color: var(--color-text-white);
  margin-bottom: 5rem;
  text-align: center
}

.error-container h3 {
  color: var(--color-text-white);
  text-align: center;
}
