.about {
  width: 100%;
  height: 60rem;  
  background: var(--color-background);
} 
.about .about-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.about .about-content .about-image {
  width: 30rem;
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}
.about .about-content .about-image img {
  width: 100%;
  height: 100%;
}
.about .about-content .about-text {
  width: 33rem;
  height: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;  
}
.about .about-content .about-text strong {
  font: 700 2.5rem barlow;
  color: var(--color-text-brown);
}
.about .about-content .about-text p {
  width: 100%;
  font: 300 1.5rem barlow;
  text-align: center;
  line-height: 2rem;
}
@media (min-width: 600px) {
  .about {
    height: 50rem;   
  } 

  .about .about-content .about-text {
    width: 50rem;
    height: 50rem;
  }
}
@media (min-width: 1200px) {
  .about {
    height: 55rem;
  }
  .about .about-content {
    flex-direction: row;
  }
  .about .about-content .about-image {
    width: 50rem;
    height: 50rem;
  }
  .about .about-content .about-text {
    width: 50%;
  }
  .about .about-content .about-text strong {
    font: 700 4rem barlow;
  }
}
@media (min-width: 1300px) {
  .about .about-content .about-text p {
    font: 300 1.8rem barlow;
    line-height: 2.5rem;
  }
}
@media (min-width: 1500px) {
  .about .about-content .about-text p {
    font: 300 2rem barlow;
    line-height: 2.7rem;
  }
}
@media (min-width: 1600px) {
  .about .about-content .about-text p {
    font: 300 2rem barlow;
    line-height: 2.7rem;
  }
}