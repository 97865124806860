#unsubscribe-page {
	width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background-light));
}

.unsubscribe {
  width: 100%;
  height: 50rem;  
  background-image: url('../../assets/images/background_banner.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.unsubscribe .unsubscribe-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;  
}

.unsubscribe .unsubscribe-content #loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.unsubscribe .unsubscribe-content .unsubscribe-main {
  width: 93%;
  height: 93%;  
  border-style: solid;
  border-color: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.unsubscribe .unsubscribe-content .unsubscribe-main strong {
  font: 700 2.5rem barlow;
  color: var(--color-text-white);
  text-shadow: 2px 2px var(--color-text-blue);
  z-index: 1;
}

.unsubscribe .unsubscribe-content .unsubscribe-main img {
  width: 23.38rem;
  height: 14rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -185%);
  z-index: 0;  
}

.unsubscribe .unsubscribe-content .unsubscribe-main .unsubscribe-box {
  width: 28rem;
  height: 3.8rem;
  border-style: solid;
  border-radius: 5rem;
  background-color: rgba(255, 255, 255, 0.8);
  border-color: var(--color-background-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -260%);
}

.unsubscribe .unsubscribe-content .unsubscribe-main .unsubscribe-box strong {
  font: 700 2.2rem barlow;
  color: var(--color-text-brown);
  text-shadow: none;  
}

.unsubscribe .unsubscribe-content .unsubscribe-main .form-box {
  width: 90%;
  height: 25rem;
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background-light));
  border: 0;
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 9rem;
}

.unsubscribe .unsubscribe-content .unsubscribe-main .form-box form {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3rem; 
}

.unsubscribe .unsubscribe-content .unsubscribe-main .form-box form p {
  margin-bottom: 0.5rem;
  color: var(--color-text-white);
}

.unsubscribe .unsubscribe-content .unsubscribe-main .form-box form .button-box {
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 2rem;
}

.unsubscribe .unsubscribe-content .unsubscribe-main .form-box form .button-box button {
  width: 12rem;
  height: 3rem;
  background: var(--color-button-white);
  color: var(--color-button-text);
  border: 0;
  border-radius: 3rem;
  cursor: pointer;
  font: 600 1.5rem barlow;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.2s;   
}

.unsubscribe .unsubscribe-content .unsubscribe-main .form-box form .button-box button:hover {
  color: var(--color-button-text-hover);
}

@media (min-width: 600px) {
  .unsubscribe .unsubscribe-content .unsubscribe-main {
    width: 95%;
  }

  .unsubscribe .unsubscribe-content .unsubscribe-main strong {
    font: 700 4rem barlow;    
  }

  .unsubscribe .unsubscribe-content .unsubscribe-main img {
    transform: translate(-50%, -180%);     
  }
  
  .unsubscribe .unsubscribe-content .unsubscribe-main .unsubscribe-box {
    width: 28rem;
    height: 3.8rem;    
    transform: translate(-50%, -240%);
  }

  .unsubscribe .unsubscribe-content .unsubscribe-main .form-box {
    width: 70%;
    margin-top: 10.5rem;
  }
}

@media (min-width: 1200px) {
  .unsubscribe {
    height: 60rem;
  }

  .unsubscribe .unsubscribe-content .unsubscribe-main {
    width: 97%;
  }

  .unsubscribe .unsubscribe-content .unsubscribe-main strong {
    font: 700 5.5rem barlow;
    margin-top: 2rem;
    margin-bottom: 2rem;   
  }

  .unsubscribe .unsubscribe-content .unsubscribe-main img {
    width: 30.06rem;
    height: 18rem;
    transform: translate(-50%, -155%);     
  }
  
  .unsubscribe .unsubscribe-content .unsubscribe-main .unsubscribe-box {
    width: 35rem;
    height: 4.5rem;    
    transform: translate(-50%, -140%);
  }

  .unsubscribe .unsubscribe-content .unsubscribe-main .unsubscribe-box strong {
    font: 700 3rem barlow;    
  }

  .unsubscribe .unsubscribe-content .unsubscribe-main .form-box {
    width: 50%;
    margin-top: 16.8rem;
  }
}
