#study-group-form-page {
	width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background-light));
}

.study-group-form {
  width: 100%;
  height: 80rem;  
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background-light));
}

.study-group-form .study-group-form-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;  
}

.study-group-form .study-group-form-content #loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.study-group-form .study-group-form-content strong {
  font: 700 4rem barlow;
  color: var(--color-text-white);
  text-shadow: 2px 2px var(--color-text-dark-gray);
}

.study-group-form .study-group-form-content .form-box {
  width: 90%;
  height: 70%;
  background: var(--color-background);
  border: 0;
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;  
}

.study-group-form .study-group-form-content .form-box img {
  width: 20.04rem;
  height: 12rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 170%);  
}

.study-group-form .study-group-form-content .form-box form {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.study-group-form .study-group-form-content .form-box form p {
  margin-bottom: 0.5rem;
}

.study-group-form .study-group-form-content .form-box form .button-box {
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 2rem;
}

.study-group-form .study-group-form-content .form-box form .button-box button {
  width: 12rem;
  height: 3rem;
  background: var(--color-button);
  color: var(--color-button-text-light);
  border: 0;
  border-radius: 3rem;
  cursor: pointer;
  font: 600 1.5rem barlow;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.2s;   
}

.study-group-form .study-group-form-content .form-box form .button-box button:hover {
  color: var(--color-button-text-light-hover);
}

@media (min-width: 600px) {
  .study-group-form .study-group-form-content .form-box {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .study-group-form .study-group-form-content .form-box {
    width: 40%;
  }
}