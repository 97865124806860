#contact-me-page {
	width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background-light));
}

.contact-me {
  width: 100%;
  height: 80rem;  
  background-image: url('../../assets/images/background_banner.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.contact-me .contact-me-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;  
}

.contact-me .contact-me-content #loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact-me .contact-me-content .contact-me-main {
  width: 93%;
  height: 93%;  
  border-style: solid;
  border-color: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.contact-me .contact-me-content .contact-me-main strong {
  font: 700 2.5rem barlow;
  color: var(--color-text-white);
  text-shadow: 2px 2px var(--color-text-blue);
  z-index: 1;
}

.contact-me .contact-me-content .contact-me-main img {
  width: 23.38rem;
  height: 14rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -275%);
  z-index: 0;  
}

.contact-me .contact-me-content .contact-me-main .contact-box {
  width: 20rem;
  height: 3.8rem;
  border-style: solid;
  border-radius: 5rem;
  background-color: rgba(255, 255, 255, 0.8);
  border-color: var(--color-background-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -610%);
}

.contact-me .contact-me-content .contact-me-main .contact-box strong {
  font: 700 2.2rem barlow;
  color: var(--color-text-brown);
  text-shadow: none;  
}

.contact-me .contact-me-content .contact-me-main .form-box {
  width: 90%;
  height: 70%;
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background-light));
  border: 0;
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 9.2rem;
}

.contact-me .contact-me-content .contact-me-main .form-box form {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;  
}

.contact-me .contact-me-content .contact-me-main .form-box form p {
  margin-bottom: 0.5rem;
  color: var(--color-text-white);
}

.contact-me .contact-me-content .contact-me-main .form-box form .button-box {
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 2rem;
}

.contact-me .contact-me-content .contact-me-main .form-box form .button-box button {
  width: 12rem;
  height: 3rem;
  background: var(--color-button-white);
  color: var(--color-button-text);
  border: 0;
  border-radius: 3rem;
  cursor: pointer;
  font: 600 1.5rem barlow;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.2s;   
}

.contact-me .contact-me-content .contact-me-main .form-box form .button-box button:hover {
  color: var(--color-button-text-hover);
}

@media (min-width: 600px) {
  .contact-me .contact-me-content .contact-me-main {
    width: 95%;
  }

  .contact-me .contact-me-content .contact-me-main strong {
    font: 700 4rem barlow;    
  }

  .contact-me .contact-me-content .contact-me-main img {
    transform: translate(-50%, -270%);     
  }
  
  .contact-me .contact-me-content .contact-me-main .contact-box {
    width: 20rem;
    height: 3.8rem;    
    transform: translate(-50%, -590%);
  }

  .contact-me .contact-me-content .contact-me-main .form-box {
    width: 70%;
    margin-top: 10.6rem;
  }
}

@media (min-width: 1200px) {
  .contact-me {
    height: 80rem;
  }

  .contact-me .contact-me-content .contact-me-main {
    width: 97%;
  }

  .contact-me .contact-me-content .contact-me-main strong {
    font: 700 5.5rem barlow;
    margin-top: 2rem;
    margin-bottom: 2rem;   
  }

  .contact-me .contact-me-content .contact-me-main img {
    width: 30.06rem;
    height: 18rem;
    transform: translate(-50%, -220%);     
  }
  
  .contact-me .contact-me-content .contact-me-main .contact-box {
    width: 27rem;
    height: 4.5rem;    
    transform: translate(-50%, -465%);
  }

  .contact-me .contact-me-content .contact-me-main .contact-box strong {
    font: 700 3rem barlow;    
  }

  .contact-me .contact-me-content .contact-me-main .form-box {
    width: 50%;
    margin-top: 9em;
  }
}
