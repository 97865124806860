.ingrid {
  height: 38rem;  
  background: var(--color-background);  
}

.ingrid .ingrid-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;  
}

.ingrid .ingrid-content .fundo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ingrid .ingrid-content .fundo .ingrid-main {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ingrid .ingrid-content .fundo .ingrid-main .appointment {
  width: 100%;
  height: 10rem;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.ingrid .ingrid-content .fundo .ingrid-main .appointment a {
  text-decoration-color:  var(--color-text-brown);
}

.ingrid .ingrid-content .fundo .ingrid-main .appointment h2 {
  width: 100%;
  font: 700 2rem barlow;
  color: var(--color-text-brown);
  text-align: center;
  margin-bottom: 0;
}

.ingrid .ingrid-content .fundo .ingrid-main h2 {
  font: 700 2rem barlow;
  color: var(--color-text-brown);
  text-align: center;
  margin-bottom: 2rem;
}

.ingrid .ingrid-content .fundo .ingrid-main .block {
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.ingrid .ingrid-content .fundo .ingrid-main .block .image {
  width: 15rem;
  height: 15rem; 
}

.ingrid .ingrid-content .fundo .ingrid-main .block .image #seta{
  width: 100%;
  height: 10%;
}

.ingrid .ingrid-content .fundo .ingrid-main .block .text {
  width: 21rem;
  height: 22rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;  
}

.ingrid .ingrid-content .fundo .ingrid-main .block .image img {
  width: 100%;
  height: 100%;
}

.ingrid .ingrid-content .fundo .ingrid-main .block .text p {
  font: italic 400 1.5rem barlow;
  color: var(--color-text-complement);
  text-align: center;
  line-height: 2rem;
  margin-top: 1rem;
}
 .ingrid .ingrid-content .fundo .ingrid-main .block .text #com-carinho {
  margin-top: 0;
  margin-bottom: 1rem;
 }

.ingrid .ingrid-content .fundo .ingrid-main .block .text img {
  width: 20.76rem;
  height: 4rem;
}

@media (min-width: 600px) {
  .ingrid {
    height: 50rem;
  }

  .ingrid .ingrid-content .fundo .ingrid-main h2 {
    width: 80%;
    font: 700 2.5rem barlow;   
    margin-bottom: 2rem;
  }

  .ingrid .ingrid-content .fundo .ingrid-main .appointment h2 {   
    font: 700 2.5rem barlow;   
  }

  .ingrid .ingrid-content .fundo .ingrid-main .block {
    height: 30rem;    
  }

  .ingrid .ingrid-content .fundo .ingrid-main .block .image {
    width: 22rem;
    height: 22rem;    
  }
  
  .ingrid .ingrid-content .fundo .ingrid-main .block .text {
    width: 35rem;
    height: 30rem;    
  }

  .ingrid .ingrid-content .fundo .ingrid-main .block .text p {
    font: italic 400 2rem barlow;    
    line-height: 2.5rem;
  }
  
  .ingrid .ingrid-content .fundo .ingrid-main .block .text img {
    width: 31.14rem;
    height: 6rem;
  } 
}

@media (min-width: 1200px) {
  .ingrid {
    height: 75rem;
  }

  .ingrid .ingrid-content .fundo .ingrid-main .appointment {   
    margin-top: 5rem;
  }

  .ingrid .ingrid-content .fundo .ingrid-main .appointment h2 {   
    font: 700 3rem barlow;   
  }

  .ingrid .ingrid-content .fundo .ingrid-main h2 {
    width: 80%;
    font: 700 4.5rem barlow;   
    margin-bottom: 2rem;
  }

  .ingrid .ingrid-content .fundo .ingrid-main .block {
    height: 40rem;
    justify-content: center; 
  }

  .ingrid .ingrid-content .fundo .ingrid-main .block .image {
    width: 35rem;
    height: 35rem;
    margin-right: 12rem;  
  }
  
  .ingrid .ingrid-content .fundo .ingrid-main .block .text {
    width: 40rem;
    height: 45rem;    
  }

  .ingrid .ingrid-content .fundo .ingrid-main .block .text p {
    font: italic 400 2.8rem barlow;    
    line-height: 3rem;
  }
  
  .ingrid .ingrid-content .fundo .ingrid-main .block .text img {
    width: 41.52rem;
    height: 8rem;
  }  
}
